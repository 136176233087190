import React from 'react';
import axios from 'axios';

import { PREFIX_API } from '../Configuration';

const WebclientContext = React.createContext();

export const WebclientURL = {
    login: PREFIX_API + '/user/login',
    signup: PREFIX_API + '/user/signup',
    reset: PREFIX_API + '/user/reset',
    newpassword: PREFIX_API + '/user/newpassword',
    chef_login: PREFIX_API + '/userchef/login',
    chef_signup: PREFIX_API + '/userchef/signup',
    chef_reset: PREFIX_API + '/userchef/reset',
    chef_newpassword: PREFIX_API + '/userchef/newpassword',

    social_google_login: PREFIX_API + '/oauth/google/login',
    social_google_callback: PREFIX_API + '/oauth/google/callback',

    dishes: PREFIX_API + '/dishes?days=(days)&today=(today)',
    chefs: PREFIX_API + '/chefs',
    chef: PREFIX_API + '/chef',

    profile: PREFIX_API + '/user/profile',

    orders_new: PREFIX_API + '/orders/new',
    orders: PREFIX_API + '/orders',
    order_status: PREFIX_API + '/order_status/(order_id)',
    orders_pay: PREFIX_API + '/pay/create_payment',

    userchef_dishes: PREFIX_API + '/userchef/dishes',
    userchef_dish: PREFIX_API + '/userchef/dish',
    userchef_dish_delete: PREFIX_API + '/userchef/dish/(dish_id)/delete',
    userchef_dish_photo: PREFIX_API + '/userchef/dish/(dish_id)/files',
    userchef_dish_photo_delete: PREFIX_API + '/userchef/dish/(dish_id)/files/delete',

    userchef_calendar: PREFIX_API + '/userchef/calendar',

    userchef_orders: PREFIX_API + '/userchef/orders',
    userchef_order_status: PREFIX_API + '/userchef/order_status/(order_id)',

    userchef_settings: PREFIX_API + '/userchef/settings',
    userchef_settings_stripe_link: PREFIX_API + '/userchef/stripe_link',
    userchef_settings_stripe_link_verify: PREFIX_API + '/userchef/stripe_link_verify',
};

const AUTHORIZATION_HEADER = 'Authorization';

export default function WebclientProvider({children}) {
    const webclient = axios.create({
        baseURL: PREFIX_API,
        timeout: 5000,
        validateStatus: (status) => {
            if ((status >= 200) && (status < 300)) return true;
            if ((status >= 400) && (status < 500)) return true;
            return false;
        },
    });

    const doPost = ({url, data}) => {
        return webclient.post(url, data);
    };

    const doGet = ({url, data}) => {
        return webclient.get(url, data);
    };

    const setAuthorization = (token) => {
        const headers = webclient.defaults.headers;
        if (token) {
            headers.common[AUTHORIZATION_HEADER] = 'Bearer ' + token;
        } else {
            headers.common = Object.fromEntries(
                Object.entries(headers.common).filter((item) => item[0] !== AUTHORIZATION_HEADER)
            );
        }
    };

    const values = {
    };

    const funcs = {
        post: doPost,
        get: doGet,
        setAuthorization: setAuthorization,
    };

    return (
        <WebclientContext.Provider value={{...values, ...funcs}}>
            {children}
        </WebclientContext.Provider>
    )
}

export const useWebclient = () => React.useContext(WebclientContext);
