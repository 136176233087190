import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/cnt.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';

const MainApp = (
    <React.StrictMode>
        <Router><App /></Router>
    </React.StrictMode>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(MainApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
